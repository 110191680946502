import * as _envs2 from "node-releases/data/processed/envs.json";

var _envs = "default" in _envs2 ? _envs2.default : _envs2;

import * as _agents2 from "caniuse-lite/dist/unpacker/agents";

var _agents = "default" in _agents2 ? _agents2.default : _agents2;

import * as _releaseSchedule2 from "node-releases/data/release-schedule/release-schedule.json";

var _releaseSchedule = "default" in _releaseSchedule2 ? _releaseSchedule2.default : _releaseSchedule2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _versions2 from "electron-to-chromium/versions";

var _versions = "default" in _versions2 ? _versions2.default : _versions2;

import _error from "./error";
import _node from "./node";
import _process from "process";
var exports = {};
var process = _process;
var jsReleases = _envs;
var agents = _agents.agents;
var jsEOL = _releaseSchedule;
var path = _path;
var e2c = _versions;
var BrowserslistError = _error;
var env = _node; // Will load browser.js in webpack

var YEAR = 365.259641 * 24 * 60 * 60 * 1000;
var ANDROID_EVERGREEN_FIRST = 37;
var QUERY_OR = 1;
var QUERY_AND = 2;

function isVersionsMatch(versionA, versionB) {
  return (versionA + ".").indexOf(versionB + ".") === 0;
}

function isEolReleased(name) {
  var version = name.slice(1);
  return jsReleases.some(function (i) {
    return isVersionsMatch(i.version, version);
  });
}

function normalize(versions) {
  return versions.filter(function (version) {
    return typeof version === "string";
  });
}

function normalizeElectron(version) {
  var versionToUse = version;

  if (version.split(".").length === 3) {
    versionToUse = version.split(".").slice(0, -1).join(".");
  }

  return versionToUse;
}

function nameMapper(name) {
  return function mapName(version) {
    return name + " " + version;
  };
}

function getMajor(version) {
  return parseInt(version.split(".")[0]);
}

function getMajorVersions(released, number) {
  if (released.length === 0) return [];
  var majorVersions = uniq(released.map(getMajor));
  var minimum = majorVersions[majorVersions.length - number];

  if (!minimum) {
    return released;
  }

  var selected = [];

  for (var i = released.length - 1; i >= 0; i--) {
    if (minimum > getMajor(released[i])) break;
    selected.unshift(released[i]);
  }

  return selected;
}

function uniq(array) {
  var filtered = [];

  for (var i = 0; i < array.length; i++) {
    if (filtered.indexOf(array[i]) === -1) filtered.push(array[i]);
  }

  return filtered;
} // Helpers


function fillUsage(result, name, data) {
  for (var i in data) {
    result[name + " " + i] = data[i];
  }
}

function generateFilter(sign, version) {
  version = parseFloat(version);

  if (sign === ">") {
    return function (v) {
      return parseFloat(v) > version;
    };
  } else if (sign === ">=") {
    return function (v) {
      return parseFloat(v) >= version;
    };
  } else if (sign === "<") {
    return function (v) {
      return parseFloat(v) < version;
    };
  } else {
    return function (v) {
      return parseFloat(v) <= version;
    };
  }
}

function generateSemverFilter(sign, version) {
  version = version.split(".").map(parseSimpleInt);
  version[1] = version[1] || 0;
  version[2] = version[2] || 0;

  if (sign === ">") {
    return function (v) {
      v = v.split(".").map(parseSimpleInt);
      return compareSemver(v, version) > 0;
    };
  } else if (sign === ">=") {
    return function (v) {
      v = v.split(".").map(parseSimpleInt);
      return compareSemver(v, version) >= 0;
    };
  } else if (sign === "<") {
    return function (v) {
      v = v.split(".").map(parseSimpleInt);
      return compareSemver(version, v) > 0;
    };
  } else {
    return function (v) {
      v = v.split(".").map(parseSimpleInt);
      return compareSemver(version, v) >= 0;
    };
  }
}

function parseSimpleInt(x) {
  return parseInt(x);
}

function compare(a, b) {
  if (a < b) return -1;
  if (a > b) return +1;
  return 0;
}

function compareSemver(a, b) {
  return compare(parseInt(a[0]), parseInt(b[0])) || compare(parseInt(a[1] || "0"), parseInt(b[1] || "0")) || compare(parseInt(a[2] || "0"), parseInt(b[2] || "0"));
} // this follows the npm-like semver behavior


function semverFilterLoose(operator, range) {
  range = range.split(".").map(parseSimpleInt);

  if (typeof range[1] === "undefined") {
    range[1] = "x";
  } // ignore any patch version because we only return minor versions
  // range[2] = 'x'


  switch (operator) {
    case "<=":
      return function (version) {
        version = version.split(".").map(parseSimpleInt);
        return compareSemverLoose(version, range) <= 0;
      };

    case ">=":
    default:
      return function (version) {
        version = version.split(".").map(parseSimpleInt);
        return compareSemverLoose(version, range) >= 0;
      };
  }
} // this follows the npm-like semver behavior


function compareSemverLoose(version, range) {
  if (version[0] !== range[0]) {
    return version[0] < range[0] ? -1 : +1;
  }

  if (range[1] === "x") {
    return 0;
  }

  if (version[1] !== range[1]) {
    return version[1] < range[1] ? -1 : +1;
  }

  return 0;
}

function resolveVersion(data, version) {
  if (data.versions.indexOf(version) !== -1) {
    return version;
  } else if (browserslist.versionAliases[data.name][version]) {
    return browserslist.versionAliases[data.name][version];
  } else {
    return false;
  }
}

function normalizeVersion(data, version) {
  var resolved = resolveVersion(data, version);

  if (resolved) {
    return resolved;
  } else if (data.versions.length === 1) {
    return data.versions[0];
  } else {
    return false;
  }
}

function filterByYear(since, context) {
  since = since / 1000;
  return Object.keys(agents).reduce(function (selected, name) {
    var data = byName(name, context);
    if (!data) return selected;
    var versions = Object.keys(data.releaseDate).filter(function (v) {
      var date = data.releaseDate[v];
      return date !== null && date >= since;
    });
    return selected.concat(versions.map(nameMapper(data.name)));
  }, []);
}

function cloneData(data) {
  return {
    name: data.name,
    versions: data.versions,
    released: data.released,
    releaseDate: data.releaseDate
  };
}

function mapVersions(data, map) {
  data.versions = data.versions.map(function (i) {
    return map[i] || i;
  });
  data.released = data.versions.map(function (i) {
    return map[i] || i;
  });
  var fixedDate = {};

  for (var i in data.releaseDate) {
    fixedDate[map[i] || i] = data.releaseDate[i];
  }

  data.releaseDate = fixedDate;
  return data;
}

function byName(name, context) {
  name = name.toLowerCase();
  name = browserslist.aliases[name] || name;

  if (context.mobileToDesktop && browserslist.desktopNames[name]) {
    var desktop = browserslist.data[browserslist.desktopNames[name]];

    if (name === "android") {
      return normalizeAndroidData(cloneData(browserslist.data[name]), desktop);
    } else {
      var cloned = cloneData(desktop);
      cloned.name = name;

      if (name === "op_mob") {
        cloned = mapVersions(cloned, {
          "10.0-10.1": "10"
        });
      }

      return cloned;
    }
  }

  return browserslist.data[name];
}

function normalizeAndroidVersions(androidVersions, chromeVersions) {
  var firstEvergreen = ANDROID_EVERGREEN_FIRST;
  var last = chromeVersions[chromeVersions.length - 1];
  return androidVersions.filter(function (version) {
    return /^(?:[2-4]\.|[34]$)/.test(version);
  }).concat(chromeVersions.slice(firstEvergreen - last - 1));
}

function normalizeAndroidData(android, chrome) {
  android.released = normalizeAndroidVersions(android.released, chrome.released);
  android.versions = normalizeAndroidVersions(android.versions, chrome.versions);
  return android;
}

function checkName(name, context) {
  var data = byName(name, context);
  if (!data) throw new BrowserslistError("Unknown browser " + name);
  return data;
}

function unknownQuery(query) {
  return new BrowserslistError("Unknown browser query `" + query + "`. " + "Maybe you are using old Browserslist or made typo in query.");
}

function filterAndroid(list, versions, context) {
  if (context.mobileToDesktop) return list;
  var released = browserslist.data.android.released;
  var last = released[released.length - 1];
  var diff = last - ANDROID_EVERGREEN_FIRST - versions;

  if (diff > 0) {
    return list.slice(-1);
  } else {
    return list.slice(diff - 1);
  }
}
/**
 * Resolves queries into a browser list.
 * @param {string|string[]} queries Queries to combine.
 * Either an array of queries or a long string of queries.
 * @param {object} [context] Optional arguments to
 * the select function in `queries`.
 * @returns {string[]} A list of browsers
 */


function resolve(queries, context) {
  if (Array.isArray(queries)) {
    queries = flatten(queries.map(parse));
  } else {
    queries = parse(queries);
  }

  return queries.reduce(function (result, query, index) {
    var selection = query.queryString;
    var isExclude = selection.indexOf("not ") === 0;

    if (isExclude) {
      if (index === 0) {
        throw new BrowserslistError("Write any browsers query (for instance, `defaults`) " + "before `" + selection + "`");
      }

      selection = selection.slice(4);
    }

    for (var i = 0; i < QUERIES.length; i++) {
      var type = QUERIES[i];
      var match = selection.match(type.regexp);

      if (match) {
        var args = [context].concat(match.slice(1));
        var array = type.select.apply(browserslist, args).map(function (j) {
          var parts = j.split(" ");

          if (parts[1] === "0") {
            return parts[0] + " " + byName(parts[0], context).versions[0];
          } else {
            return j;
          }
        });

        switch (query.type) {
          case QUERY_AND:
            if (isExclude) {
              return result.filter(function (j) {
                return array.indexOf(j) === -1;
              });
            } else {
              return result.filter(function (j) {
                return array.indexOf(j) !== -1;
              });
            }

          case QUERY_OR:
          default:
            if (isExclude) {
              var filter = {};
              array.forEach(function (j) {
                filter[j] = true;
              });
              return result.filter(function (j) {
                return !filter[j];
              });
            }

            return result.concat(array);
        }
      }
    }

    throw unknownQuery(selection);
  }, []);
}

var cache = {};
/**
 * Return array of browsers by selection queries.
 *
 * @param {(string|string[])} [queries=browserslist.defaults] Browser queries.
 * @param {object} [opts] Options.
 * @param {string} [opts.path="."] Path to processed file.
 *                                 It will be used to find config files.
 * @param {string} [opts.env="production"] Processing environment.
 *                                         It will be used to take right
 *                                         queries from config file.
 * @param {string} [opts.config] Path to config file with queries.
 * @param {object} [opts.stats] Custom browser usage statistics
 *                              for "> 1% in my stats" query.
 * @param {boolean} [opts.ignoreUnknownVersions=false] Do not throw on unknown
 *                                                     version in direct query.
 * @param {boolean} [opts.dangerousExtend] Disable security checks
 *                                         for extend query.
 * @param {boolean} [opts.throwOnMissing] Throw error on missing env.
 * @param {boolean} [opts.mobileToDesktop] Alias mobile browsers to the desktop
 *                                         version when Can I Use doesn't have
 *                                         data about the specified version.
 * @returns {string[]} Array with browser names in Can I Use.
 *
 * @example
 * browserslist('IE >= 10, IE 8') //=> ['ie 11', 'ie 10', 'ie 8']
 */

function browserslist(queries, opts) {
  if (typeof opts === "undefined") opts = {};

  if (typeof opts.path === "undefined") {
    opts.path = path.resolve ? path.resolve(".") : ".";
  }

  if (typeof queries === "undefined" || queries === null) {
    var config = browserslist.loadConfig(opts);

    if (config) {
      queries = config;
    } else {
      queries = browserslist.defaults;
    }
  }

  if (!(typeof queries === "string" || Array.isArray(queries))) {
    throw new BrowserslistError("Browser queries must be an array or string. Got " + typeof queries + ".");
  }

  var context = {
    ignoreUnknownVersions: opts.ignoreUnknownVersions,
    dangerousExtend: opts.dangerousExtend,
    mobileToDesktop: opts.mobileToDesktop,
    path: opts.path,
    env: opts.env
  };
  env.oldDataWarning(browserslist.data);
  var stats = env.getStat(opts, browserslist.data);

  if (stats) {
    context.customUsage = {};

    for (var browser in stats) {
      fillUsage(context.customUsage, browser, stats[browser]);
    }
  }

  var cacheKey = JSON.stringify([queries, context]);
  if (cache[cacheKey]) return cache[cacheKey];
  var result = uniq(resolve(queries, context)).sort(function (name1, name2) {
    name1 = name1.split(" ");
    name2 = name2.split(" ");

    if (name1[0] === name2[0]) {
      // assumptions on caniuse data
      // 1) version ranges never overlaps
      // 2) if version is not a range, it never contains `-`
      var version1 = name1[1].split("-")[0];
      var version2 = name2[1].split("-")[0];
      return compareSemver(version2.split("."), version1.split("."));
    } else {
      return compare(name1[0], name2[0]);
    }
  });

  if (!process.env.BROWSERSLIST_DISABLE_CACHE) {
    cache[cacheKey] = result;
  }

  return result;
}

function parse(queries) {
  var qs = [];

  do {
    queries = doMatch(queries, qs);
  } while (queries);

  return qs;
}

function doMatch(string, qs) {
  var or = /^(?:,\s*|\s+or\s+)(.*)/i;
  var and = /^\s+and\s+(.*)/i;
  return find(string, function (parsed, n, max) {
    if (and.test(parsed)) {
      qs.unshift({
        type: QUERY_AND,
        queryString: parsed.match(and)[1]
      });
      return true;
    } else if (or.test(parsed)) {
      qs.unshift({
        type: QUERY_OR,
        queryString: parsed.match(or)[1]
      });
      return true;
    } else if (n === max) {
      qs.unshift({
        type: QUERY_OR,
        queryString: parsed.trim()
      });
      return true;
    }

    return false;
  });
}

function find(string, predicate) {
  for (var n = 1, max = string.length; n <= max; n++) {
    var parsed = string.substr(-n, n);

    if (predicate(parsed, n, max)) {
      return string.slice(0, -n);
    }
  }

  return "";
}

function flatten(array) {
  if (!Array.isArray(array)) return [array];
  return array.reduce(function (a, b) {
    return a.concat(flatten(b));
  }, []);
} // Will be filled by Can I Use data below


browserslist.cache = {};
browserslist.data = {};
browserslist.usage = {
  global: {},
  custom: null
}; // Default browsers query

browserslist.defaults = ["> 0.5%", "last 2 versions", "Firefox ESR", "not dead"]; // Browser names aliases

browserslist.aliases = {
  fx: "firefox",
  ff: "firefox",
  ios: "ios_saf",
  explorer: "ie",
  blackberry: "bb",
  explorermobile: "ie_mob",
  operamini: "op_mini",
  operamobile: "op_mob",
  chromeandroid: "and_chr",
  firefoxandroid: "and_ff",
  ucandroid: "and_uc",
  qqandroid: "and_qq"
}; // Can I Use only provides a few versions for some browsers (e.g. and_chr).
// Fallback to a similar browser for unknown versions

browserslist.desktopNames = {
  and_chr: "chrome",
  and_ff: "firefox",
  ie_mob: "ie",
  op_mob: "opera",
  android: "chrome" // has extra processing logic

}; // Aliases to work with joined versions like `ios_saf 7.0-7.1`

browserslist.versionAliases = {};
browserslist.clearCaches = env.clearCaches;
browserslist.parseConfig = env.parseConfig;
browserslist.readConfig = env.readConfig;
browserslist.findConfig = env.findConfig;
browserslist.loadConfig = env.loadConfig;
/**
 * Return browsers market coverage.
 *
 * @param {string[]} browsers Browsers names in Can I Use.
 * @param {string|object} [stats="global"] Which statistics should be used.
 *                                         Country code or custom statistics.
 *                                         Pass `"my stats"` to load statistics
 *                                         from Browserslist files.
 *
 * @return {number} Total market coverage for all selected browsers.
 *
 * @example
 * browserslist.coverage(browserslist('> 1% in US'), 'US') //=> 83.1
 */

browserslist.coverage = function (browsers, stats) {
  var data;

  if (typeof stats === "undefined") {
    data = browserslist.usage.global;
  } else if (stats === "my stats") {
    var opts = {};
    opts.path = path.resolve ? path.resolve(".") : ".";
    var customStats = env.getStat(opts);

    if (!customStats) {
      throw new BrowserslistError("Custom usage statistics was not provided");
    }

    data = {};

    for (var browser in customStats) {
      fillUsage(data, browser, customStats[browser]);
    }
  } else if (typeof stats === "string") {
    if (stats.length > 2) {
      stats = stats.toLowerCase();
    } else {
      stats = stats.toUpperCase();
    }

    env.loadCountry(browserslist.usage, stats, browserslist.data);
    data = browserslist.usage[stats];
  } else {
    if ("dataByBrowser" in stats) {
      stats = stats.dataByBrowser;
    }

    data = {};

    for (var name in stats) {
      for (var version in stats[name]) {
        data[name + " " + version] = stats[name][version];
      }
    }
  }

  return browsers.reduce(function (all, i) {
    var usage = data[i];

    if (usage === undefined) {
      usage = data[i.replace(/ \S+$/, " 0")];
    }

    return all + (usage || 0);
  }, 0);
};

function nodeQuery(context, version) {
  var nodeReleases = jsReleases.filter(function (i) {
    return i.name === "nodejs";
  });
  var matched = nodeReleases.filter(function (i) {
    return isVersionsMatch(i.version, version);
  });

  if (matched.length === 0) {
    if (context.ignoreUnknownVersions) {
      return [];
    } else {
      throw new BrowserslistError("Unknown version " + version + " of Node.js");
    }
  }

  return ["node " + matched[matched.length - 1].version];
}

function sinceQuery(context, year, month, date) {
  year = parseInt(year);
  month = parseInt(month || "01") - 1;
  date = parseInt(date || "01");
  return filterByYear(Date.UTC(year, month, date, 0, 0, 0), context);
}

function coverQuery(context, coverage, statMode) {
  coverage = parseFloat(coverage);
  var usage = browserslist.usage.global;

  if (statMode) {
    if (statMode.match(/^my\s+stats$/i)) {
      if (!context.customUsage) {
        throw new BrowserslistError("Custom usage statistics was not provided");
      }

      usage = context.customUsage;
    } else {
      var place;

      if (statMode.length === 2) {
        place = statMode.toUpperCase();
      } else {
        place = statMode.toLowerCase();
      }

      env.loadCountry(browserslist.usage, place, browserslist.data);
      usage = browserslist.usage[place];
    }
  }

  var versions = Object.keys(usage).sort(function (a, b) {
    return usage[b] - usage[a];
  });
  var coveraged = 0;
  var result = [];
  var version;

  for (var i = 0; i < versions.length; i++) {
    version = versions[i];
    if (usage[version] === 0) break;
    coveraged += usage[version];
    result.push(version);
    if (coveraged >= coverage) break;
  }

  return result;
}

var QUERIES = [{
  regexp: /^last\s+(\d+)\s+major\s+versions?$/i,
  select: function (context, versions) {
    return Object.keys(agents).reduce(function (selected, name) {
      var data = byName(name, context);
      if (!data) return selected;
      var list = getMajorVersions(data.released, versions);
      list = list.map(nameMapper(data.name));

      if (data.name === "android") {
        list = filterAndroid(list, versions, context);
      }

      return selected.concat(list);
    }, []);
  }
}, {
  regexp: /^last\s+(\d+)\s+versions?$/i,
  select: function (context, versions) {
    return Object.keys(agents).reduce(function (selected, name) {
      var data = byName(name, context);
      if (!data) return selected;
      var list = data.released.slice(-versions);
      list = list.map(nameMapper(data.name));

      if (data.name === "android") {
        list = filterAndroid(list, versions, context);
      }

      return selected.concat(list);
    }, []);
  }
}, {
  regexp: /^last\s+(\d+)\s+electron\s+major\s+versions?$/i,
  select: function (context, versions) {
    var validVersions = getMajorVersions(Object.keys(e2c), versions);
    return validVersions.map(function (i) {
      return "chrome " + e2c[i];
    });
  }
}, {
  regexp: /^last\s+(\d+)\s+(\w+)\s+major\s+versions?$/i,
  select: function (context, versions, name) {
    var data = checkName(name, context);
    var validVersions = getMajorVersions(data.released, versions);
    var list = validVersions.map(nameMapper(data.name));

    if (data.name === "android") {
      list = filterAndroid(list, versions, context);
    }

    return list;
  }
}, {
  regexp: /^last\s+(\d+)\s+electron\s+versions?$/i,
  select: function (context, versions) {
    return Object.keys(e2c).slice(-versions).map(function (i) {
      return "chrome " + e2c[i];
    });
  }
}, {
  regexp: /^last\s+(\d+)\s+(\w+)\s+versions?$/i,
  select: function (context, versions, name) {
    var data = checkName(name, context);
    var list = data.released.slice(-versions).map(nameMapper(data.name));

    if (data.name === "android") {
      list = filterAndroid(list, versions, context);
    }

    return list;
  }
}, {
  regexp: /^unreleased\s+versions$/i,
  select: function (context) {
    return Object.keys(agents).reduce(function (selected, name) {
      var data = byName(name, context);
      if (!data) return selected;
      var list = data.versions.filter(function (v) {
        return data.released.indexOf(v) === -1;
      });
      list = list.map(nameMapper(data.name));
      return selected.concat(list);
    }, []);
  }
}, {
  regexp: /^unreleased\s+electron\s+versions?$/i,
  select: function () {
    return [];
  }
}, {
  regexp: /^unreleased\s+(\w+)\s+versions?$/i,
  select: function (context, name) {
    var data = checkName(name, context);
    return data.versions.filter(function (v) {
      return data.released.indexOf(v) === -1;
    }).map(nameMapper(data.name));
  }
}, {
  regexp: /^last\s+(\d*.?\d+)\s+years?$/i,
  select: function (context, years) {
    return filterByYear(Date.now() - YEAR * years, context);
  }
}, {
  regexp: /^since (\d+)$/i,
  select: sinceQuery
}, {
  regexp: /^since (\d+)-(\d+)$/i,
  select: sinceQuery
}, {
  regexp: /^since (\d+)-(\d+)-(\d+)$/i,
  select: sinceQuery
}, {
  regexp: /^(>=?|<=?)\s*(\d+|\d+\.\d+|\.\d+)%$/,
  select: function (context, sign, popularity) {
    popularity = parseFloat(popularity);
    var usage = browserslist.usage.global;
    return Object.keys(usage).reduce(function (result, version) {
      if (sign === ">") {
        if (usage[version] > popularity) {
          result.push(version);
        }
      } else if (sign === "<") {
        if (usage[version] < popularity) {
          result.push(version);
        }
      } else if (sign === "<=") {
        if (usage[version] <= popularity) {
          result.push(version);
        }
      } else if (usage[version] >= popularity) {
        result.push(version);
      }

      return result;
    }, []);
  }
}, {
  regexp: /^(>=?|<=?)\s*(\d+|\d+\.\d+|\.\d+)%\s+in\s+my\s+stats$/,
  select: function (context, sign, popularity) {
    popularity = parseFloat(popularity);

    if (!context.customUsage) {
      throw new BrowserslistError("Custom usage statistics was not provided");
    }

    var usage = context.customUsage;
    return Object.keys(usage).reduce(function (result, version) {
      var percentage = usage[version];

      if (percentage == null) {
        return result;
      }

      if (sign === ">") {
        if (percentage > popularity) {
          result.push(version);
        }
      } else if (sign === "<") {
        if (percentage < popularity) {
          result.push(version);
        }
      } else if (sign === "<=") {
        if (percentage <= popularity) {
          result.push(version);
        }
      } else if (percentage >= popularity) {
        result.push(version);
      }

      return result;
    }, []);
  }
}, {
  regexp: /^(>=?|<=?)\s*(\d+|\d+\.\d+|\.\d+)%\s+in\s+(\S+)\s+stats$/,
  select: function (context, sign, popularity, name) {
    popularity = parseFloat(popularity);
    var stats = env.loadStat(context, name, browserslist.data);

    if (stats) {
      context.customUsage = {};

      for (var browser in stats) {
        fillUsage(context.customUsage, browser, stats[browser]);
      }
    }

    if (!context.customUsage) {
      throw new BrowserslistError("Custom usage statistics was not provided");
    }

    var usage = context.customUsage;
    return Object.keys(usage).reduce(function (result, version) {
      var percentage = usage[version];

      if (percentage == null) {
        return result;
      }

      if (sign === ">") {
        if (percentage > popularity) {
          result.push(version);
        }
      } else if (sign === "<") {
        if (percentage < popularity) {
          result.push(version);
        }
      } else if (sign === "<=") {
        if (percentage <= popularity) {
          result.push(version);
        }
      } else if (percentage >= popularity) {
        result.push(version);
      }

      return result;
    }, []);
  }
}, {
  regexp: /^(>=?|<=?)\s*(\d+|\d+\.\d+|\.\d+)%\s+in\s+((alt-)?\w\w)$/,
  select: function (context, sign, popularity, place) {
    popularity = parseFloat(popularity);

    if (place.length === 2) {
      place = place.toUpperCase();
    } else {
      place = place.toLowerCase();
    }

    env.loadCountry(browserslist.usage, place, browserslist.data);
    var usage = browserslist.usage[place];
    return Object.keys(usage).reduce(function (result, version) {
      var percentage = usage[version];

      if (percentage == null) {
        return result;
      }

      if (sign === ">") {
        if (percentage > popularity) {
          result.push(version);
        }
      } else if (sign === "<") {
        if (percentage < popularity) {
          result.push(version);
        }
      } else if (sign === "<=") {
        if (percentage <= popularity) {
          result.push(version);
        }
      } else if (percentage >= popularity) {
        result.push(version);
      }

      return result;
    }, []);
  }
}, {
  regexp: /^cover\s+(\d+|\d+\.\d+|\.\d+)%$/i,
  select: coverQuery
}, {
  regexp: /^cover\s+(\d+|\d+\.\d+|\.\d+)%\s+in\s+(my\s+stats|(alt-)?\w\w)$/i,
  select: coverQuery
}, {
  regexp: /^supports\s+([\w-]+)$/,
  select: function (context, feature) {
    env.loadFeature(browserslist.cache, feature);
    var features = browserslist.cache[feature];
    return Object.keys(features).reduce(function (result, version) {
      var flags = features[version];

      if (flags.indexOf("y") >= 0 || flags.indexOf("a") >= 0) {
        result.push(version);
      }

      return result;
    }, []);
  }
}, {
  regexp: /^electron\s+([\d.]+)\s*-\s*([\d.]+)$/i,
  select: function (context, from, to) {
    var fromToUse = normalizeElectron(from);
    var toToUse = normalizeElectron(to);

    if (!e2c[fromToUse]) {
      throw new BrowserslistError("Unknown version " + from + " of electron");
    }

    if (!e2c[toToUse]) {
      throw new BrowserslistError("Unknown version " + to + " of electron");
    }

    from = parseFloat(from);
    to = parseFloat(to);
    return Object.keys(e2c).filter(function (i) {
      var parsed = parseFloat(i);
      return parsed >= from && parsed <= to;
    }).map(function (i) {
      return "chrome " + e2c[i];
    });
  }
}, {
  regexp: /^node\s+([\d.]+)\s*-\s*([\d.]+)$/i,
  select: function (context, from, to) {
    var nodeVersions = jsReleases.filter(function (i) {
      return i.name === "nodejs";
    }).map(function (i) {
      return i.version;
    });
    return nodeVersions.filter(semverFilterLoose(">=", from)).filter(semverFilterLoose("<=", to)).map(function (v) {
      return "node " + v;
    });
  }
}, {
  regexp: /^(\w+)\s+([\d.]+)\s*-\s*([\d.]+)$/i,
  select: function (context, name, from, to) {
    var data = checkName(name, context);
    from = parseFloat(normalizeVersion(data, from) || from);
    to = parseFloat(normalizeVersion(data, to) || to);

    function filter(v) {
      var parsed = parseFloat(v);
      return parsed >= from && parsed <= to;
    }

    return data.released.filter(filter).map(nameMapper(data.name));
  }
}, {
  regexp: /^electron\s*(>=?|<=?)\s*([\d.]+)$/i,
  select: function (context, sign, version) {
    var versionToUse = normalizeElectron(version);
    return Object.keys(e2c).filter(generateFilter(sign, versionToUse)).map(function (i) {
      return "chrome " + e2c[i];
    });
  }
}, {
  regexp: /^node\s*(>=?|<=?)\s*([\d.]+)$/i,
  select: function (context, sign, version) {
    var nodeVersions = jsReleases.filter(function (i) {
      return i.name === "nodejs";
    }).map(function (i) {
      return i.version;
    });
    return nodeVersions.filter(generateSemverFilter(sign, version)).map(function (v) {
      return "node " + v;
    });
  }
}, {
  regexp: /^(\w+)\s*(>=?|<=?)\s*([\d.]+)$/,
  select: function (context, name, sign, version) {
    var data = checkName(name, context);
    var alias = browserslist.versionAliases[data.name][version];

    if (alias) {
      version = alias;
    }

    return data.released.filter(generateFilter(sign, version)).map(function (v) {
      return data.name + " " + v;
    });
  }
}, {
  regexp: /^(firefox|ff|fx)\s+esr$/i,
  select: function () {
    return ["firefox 91"];
  }
}, {
  regexp: /(operamini|op_mini)\s+all/i,
  select: function () {
    return ["op_mini all"];
  }
}, {
  regexp: /^electron\s+([\d.]+)$/i,
  select: function (context, version) {
    var versionToUse = normalizeElectron(version);
    var chrome = e2c[versionToUse];

    if (!chrome) {
      throw new BrowserslistError("Unknown version " + version + " of electron");
    }

    return ["chrome " + chrome];
  }
}, {
  regexp: /^node\s+(\d+)$/i,
  select: nodeQuery
}, {
  regexp: /^node\s+(\d+\.\d+)$/i,
  select: nodeQuery
}, {
  regexp: /^node\s+(\d+\.\d+\.\d+)$/i,
  select: nodeQuery
}, {
  regexp: /^current\s+node$/i,
  select: function (context) {
    return [env.currentNode(resolve, context)];
  }
}, {
  regexp: /^maintained\s+node\s+versions$/i,
  select: function (context) {
    var now = Date.now();
    var queries = Object.keys(jsEOL).filter(function (key) {
      return now < Date.parse(jsEOL[key].end) && now > Date.parse(jsEOL[key].start) && isEolReleased(key);
    }).map(function (key) {
      return "node " + key.slice(1);
    });
    return resolve(queries, context);
  }
}, {
  regexp: /^phantomjs\s+1.9$/i,
  select: function () {
    return ["safari 5"];
  }
}, {
  regexp: /^phantomjs\s+2.1$/i,
  select: function () {
    return ["safari 6"];
  }
}, {
  regexp: /^(\w+)\s+(tp|[\d.]+)$/i,
  select: function (context, name, version) {
    if (/^tp$/i.test(version)) version = "TP";
    var data = checkName(name, context);
    var alias = normalizeVersion(data, version);

    if (alias) {
      version = alias;
    } else {
      if (version.indexOf(".") === -1) {
        alias = version + ".0";
      } else {
        alias = version.replace(/\.0$/, "");
      }

      alias = normalizeVersion(data, alias);

      if (alias) {
        version = alias;
      } else if (context.ignoreUnknownVersions) {
        return [];
      } else {
        throw new BrowserslistError("Unknown version " + version + " of " + name);
      }
    }

    return [data.name + " " + version];
  }
}, {
  regexp: /^browserslist config$/i,
  select: function (context) {
    return browserslist(undefined, context);
  }
}, {
  regexp: /^extends (.+)$/i,
  select: function (context, name) {
    return resolve(env.loadQueries(context, name), context);
  }
}, {
  regexp: /^defaults$/i,
  select: function (context) {
    return resolve(browserslist.defaults, context);
  }
}, {
  regexp: /^dead$/i,
  select: function (context) {
    var dead = ["ie <= 10", "ie_mob <= 11", "bb <= 10", "op_mob <= 12.1", "samsung 4"];
    return resolve(dead, context);
  }
}, {
  regexp: /^(\w+)$/i,
  select: function (context, name) {
    if (byName(name, context)) {
      throw new BrowserslistError("Specify versions in Browserslist query for browser " + name);
    } else {
      throw unknownQuery(name);
    }
  }
}] // Get and convert Can I Use data
;

(function () {
  for (var name in agents) {
    var browser = agents[name];
    browserslist.data[name] = {
      name: name,
      versions: normalize(agents[name].versions),
      released: normalize(agents[name].versions.slice(0, -3)),
      releaseDate: agents[name].release_date
    };
    fillUsage(browserslist.usage.global, name, browser.usage_global);
    browserslist.versionAliases[name] = {};

    for (var i = 0; i < browser.versions.length; i++) {
      var full = browser.versions[i];
      if (!full) continue;

      if (full.indexOf("-") !== -1) {
        var interval = full.split("-");

        for (var j = 0; j < interval.length; j++) {
          browserslist.versionAliases[name][interval[j]] = full;
        }
      }
    }
  }

  browserslist.versionAliases.op_mob["59"] = "58";
})();

exports = browserslist;
export default exports;